@import '@styles/basic';
.container {
  padding: 0 toRem(12);

  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3,
    button {
      margin-bottom: toRem(40);
    }
  }
  &.noPadding {
    padding: 0;
  }
  button {
    margin-bottom: 16px;
  }
  h3 {
    margin-bottom: toRem(16);
  }
  p {
    margin-bottom: toRem(40);
  }
}
