@import '@styles/basic';
.progressBar {
  height: 8px;
  width: 100%;
  position: fixed;
  z-index: 1;
  .insideProgressBar {
    position: absolute;
    top: 0;
    background: var(--blue);
    bottom: 0;
    height: 100%;
  }
}
