@import '@styles/basic';

button.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: toRem(12) toRem(16);
  border-radius: toRem(24);
  box-sizing: border-box;
  transition: all ease-in-out 0.2s;

  .counter {
    width: 40px;
    height: 40px;
    h3 {
      color: var(--neutral600);
    }
  }

  &.fullWidth {
    width: 100%;
    justify-content: center;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > span {
    line-height: 24px;
    letter-spacing: 0.02em;
    font-size: toRem(16);
    @extend %family-maquette-regular;
    font-weight: 500;
  }

  &.text > div {
    display: none;
  }
  &.only-icon > span {
    display: none;
  }
  &.only-icon {
    border-radius: 50% !important;
    padding: toRem(12);
  }
  &.with-icon > div,
  &.with-icon > span {
    display: flex;
  }

  &.with-icon > span {
    display: flex;
  }

  &.with-icon > div {
    margin-right: toRem(8);
  }
  &.primary {
    background-color: var(--blue);
    color: var(--white);

    &:hover {
      background-color: var(--blue-hover);
    }

    &:disabled {
      background-color: var(--blue);
      opacity: 0.4;
    }

    &.outline {
      background-color: transparent;
      border: 1px solid var(--blue);
      color: var(--blue);
      &:hover {
        background-color: var(--blue-light);
        border: 1px solid var(--blue-hover);
        color: var(--blue);
      }

      &:disabled {
        background-color: transparent;
        border: 1px solid var(--blue-hover);
        color: var(--blue);
        opacity: 0.4;
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
      color: var(--blue);
      &:hover {
        background-color: var(--blue-light);
        border: none;
        color: var(--blue);
      }

      &:disabled {
        background-color: transparent;
        border: none;
        color: var(--blue);
        opacity: 0.4;
      }
    }
  }

  &.default.outline {
    background-color: transparent;
    color: var(--neutral-600);
    border: 1px solid var(--neutral200);

    &:hover {
      background-color: var(--neutral100);
      border: 1px solid var(--neutral300);
      color: var(--neutral-600);
    }

    &:disabled {
      background-color: transparent;
      opacity: 0.4;
      border: 1px solid var(--neutral200);
    }
  }

  &.default.ghost {
    background-color: transparent;
    color: var(--neutral-600);
    border: none;

    &:hover {
      background-color: var(--neutral100);
      border: none;
      color: var(--neutral-600);
    }

    &:disabled {
      background-color: transparent;
      opacity: 0.4;
      border: none;
    }
  }

  &.error {
    background-color: var(--red);
    color: var(--white);

    &:hover {
      background-color: var(--red-hover);
    }

    &:disabled {
      background-color: var(--red);
      opacity: 0.4;
    }

    &.outline {
      background-color: transparent;
      border: 1px solid var(--red);
      color: var(--red);
      &:hover {
        background-color: var(--red-light);
        border: 1px solid var(--red-hover);
        color: var(--red);
      }

      &:disabled {
        background-color: transparent;
        border: 1px solid var(--red);
        color: var(--red);
        opacity: 0.4;
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
      color: var(--red);
      &:hover {
        background-color: var(--red-light);
        border: none;
        color: var(--red);
      }

      &:disabled {
        background-color: transparent;
        border: none;
        color: var(--red);
        opacity: 0.4;
      }
    }
  }

  &.recording {
    background-color: var(--orange);
    color: var(--white);
    border: none;
    padding: toRem(20);
    &:hover {
      background-color: var(--orange-hover);
      border: none;
      color: var(--white);
    }
  }

  &.done {
    background-color: var(--green);
    color: var(--white);
    border: none;
    padding: toRem(20);
    &:hover {
      background-color: var(--green-hover);
      border: none;
      color: var(--white);
    }
  }
}
