@import '@styles/basic';

.container {
  // width: 100%;
  // max-width: 480px;
  form {
    position: relative;
    .backToLoginInForm {
      position: absolute;
      @extend %family-maquette-regular;
      right: 0;
      bottom: 12px;
      > a {
        font-size: 14px;
        letter-spacing: 0;
        line-height: toRem(24);
        text-decoration: underline;
        color: var(--neutral600);
      }
    }
    .spinnerContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .hide {
      opacity: 0;
    }
    button {
      margin-top: toRem(16);
    }
  }

  button {
    margin-top: toRem(24);
  }
}
