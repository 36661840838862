@import '@styles/basic';

.container {
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 24px;
  @include mq($until: tablet) {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 16px;
    font-size: 14px;
  }
}
