@import '@styles/basic';
@import 'sass-mq';
.wrapper {
  // max-width: 480px;

  // @include mq($until: desktop) {
  //   max-width: 100%;
  // }
  > p {
    color: var(--neutral600);
    font-size: toRem(32);
  }

  .container {
    // width: 100%;
    // position: relative;

    // width: 100%;
    // max-width: 480px;
  }

  .errorMessage {
    font-size: toRem(12);
    line-height: 1;
    margin-top: toRem(12);
    align-self: flex-start;
    color: var(--red);
  }
}
