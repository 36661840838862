@use 'sass:math';
@function toRem($value) {
  $remValue: math.div($value, 16) + rem;
  @return $remValue;
}

@mixin truncate($width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $width;
  width: 100%;
}
