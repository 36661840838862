@import '@styles/basic';

.container {
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 24px;
  @include mq($until: tablet) {
    margin-bottom: 32px;
  }
  .profilePicture {
    margin-bottom: 16px;
    & > p {
      margin-bottom: 4px;
    }
    .avatar {
      display: flex;
      align-items: center;
      & > div {
        margin-left: 0 !important;
      }
      label.labelBtn {
        display: flex;
        align-items: center;
        padding: toRem(12) toRem(16);
        border-radius: toRem(24);
        box-sizing: border-box;
        transition: all ease-in-out 0.2s;
        font-weight: 500;
        margin-left: 8px;
        margin-right: 24px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        color: var(--blue);
        &:hover {
          background-color: var(--blue-light);
          border: none;
          color: var(--blue);
        }
      }

      input[type='file'] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .trashIcon {
        flex-shrink: 0;
        cursor: pointer;
      }
      .disableIcon {
        pointer-events: none;
      }
    }
  }
  form {
    button {
      margin-top: 16px !important;
      margin-bottom: 0 !important;
    }
    .isHalf {
      display: flex;
      justify-content: space-between;
      > * {
        max-width: calc(50% - 8px);
        width: 100%;
      }

      @include mq($until: desktop) {
        flex-direction: column;
        > * {
          max-width: 100% !important;
          width: 100%;
        }
      }
    }

    .wrapper {
      margin-top: 8px;
      .inputWrapper {
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: 1px solid var(--neutral300);
        position: relative;
        padding: 1px;

        position: relative;
        textarea {
          display: block;
          color: var(--neutral600);
          width: 100%;
          padding: toRem(12) toRem(16);
          height: 100%;
          line-height: toRem(24);
          font-size: toRem(16);
          outline: 0;
          border: 0;

          &::placeholder {
            color: var(--neutral300);
          }
          &:focus + .wrapperHelper .onFocusBorder {
            opacity: 1;
          }
        }
        &:hover {
          border: 1px solid var(--neutral400);
        }
      }
    }
    .onFocusBorder {
      position: absolute;
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
      top: 0;
      pointer-events: none;
      left: 0;
      box-shadow: 0 0 0 2px var(--blue);
      border-radius: 2px;
      width: 100%;
      height: 100%;
    }

    .buttonUpdate {
      display: flex;
      align-items: center;
      margin-top: 16px;

      button {
        margin-top: 0 !important;
      }
      .error {
        color: var(--red);
        font-size: toRem(16);
        line-height: 1;
        text-align: left;
        margin-left: 8px;
        @include mq($until: tablet) {
          font-size: toRem(14);
        }
      }

      .success {
        color: var(--green);
        font-size: toRem(16);
        line-height: 1;
        text-align: left;
        margin-left: 8px;
        @include mq($until: tablet) {
          font-size: toRem(14);
        }
      }
    }
    .emailInfo {
      margin-bottom: 16px;
      margin-top: -8px;
    }
  }
}
