@import './variables';
@import 'sass-mq';
@import 'scss-reset/_reset.scss';
@import './lib/css-vars';
@import './functions';
@import './fontface';
@import './placeholders';

html {
  width: 100%;
  height: 100%;
  // height: -webkit-fill-available;

  @include css-vars(
    (
      --blue: $blue,
      --blue2: $blue2,
      --light-blue: $light-blue,
      --dark-blue: $dark-blue,
      --blue-hover: $blue-hover,
      --blue-light: $blue-light,
      --blue-light2: $blue-light2,
      --white: $white,
      --yellow: $yellow,
      --yellow-light: $yellow-light,
      --green: $green,
      --green-hover: $green-hover,
      --green-light: $green-light,
      --orange: $orange,
      --orange-hover: $orange-hover,
      --orange-light: $orange-light,
      --purple: $purple,
      --purple-light: $purple-light,
      --purple-light2: $purple-light2,
      --red: $red,
      --red-hover: $red-hover,
      --red-light: $red-light,
      --neutral100: $neutral100,
      --neutral200: $neutral200,
      --neutral300: $neutral300,
      --neutral400: $neutral400,
      --neutral500: $neutral500,
      --neutral600: $neutral600,
    ),
    'html'
  );
}

body,
html {
  @extend %family-maquette-regular;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  box-sizing: border-box;
  overscroll-behavior-y: none !important;
  text-rendering: optimizeLegibility;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: unset !important;
  // min-height: auto !important;
  // min-height: -webkit-fill-available;
  // overscroll-behavior-y: contain !important;
}

input[type='date'].isIos {
  -webkit-appearance: none !important;
  z-index: 9999 !important;
  background-color: #fff !important;
}

input[type='date'].isIos::after {
  color: var(--neutral600);
  content: attr(placeholder);
}
input[type='date'].notEmpty.isIos::after {
  content: none !important;
}

input[type='date'].isAndroid {
  -webkit-appearance: none !important;
  z-index: 9999 !important;
  background-color: #fff !important;
}

input[type='date'].isAndroid::after {
  color: var(--neutral600);
  content: attr(placeholder);
  top: 10px !important;
  position: absolute !important;
}
input[type='date'].notEmpty.isAndroid::after {
  content: none !important;
}

form {
  button {
    margin-top: 32px !important;
    @include mq($until: tablet) {
      margin: 24px 0 !important;
    }
  }
}

a {
  text-decoration: none;
  color: var(--blue);

  &:hover {
    color: var(--blue-hover);
    text-decoration: underline;
  }
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@page {
  size: auto;
  margin: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  min-height: 1px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModalPortal {
  position: fixed;
  z-index: 999999999;
  min-height: 1px;
}

.ReactModal__Content {
  outline: 0 !important;
  margin: auto;
  border-radius: 8px !important;
  border: 1px solid var(--neutral300) !important;
  padding: 0 !important;
  position: unset !important;
  width: 520px;
  bottom: auto !important;
  border: none;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);

  @include mq($until: tablet) {
    max-width: 100%;
    max-height: 100%;
    inset: 0 !important;
    width: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-right: 0 !important;
  }
}

// -----------------

.react-contexify {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 12px !important;
  min-width: 280px !important;
  border: 1px solid var(--neutral200) !important;

  padding: 0 !important;
}

.react-contexify .react-contexify__item__content {
  padding: 2px 0 !important;
  padding-bottom: 0 !important;
  display: block !important;
}

.react-contexify
  .react-contexify__item:first-child
  .react-contexify__item__content {
  padding-top: 0 !important;
}

.react-contexify
  .react-contexify__item:last-child
  .react-contexify__item__content {
  padding-bottom: 0 !important;
}

.react-contexify .react-contexify__item__content {
  background: white !important;
  color: var(--black) !important;
}

.Collapsible {
  width: 100%;
  &__contentInner {
    margin-top: 8px;
  }

  &__contentOuter {
    transition: height ease-in-out 0.2s !important;
  }

  &__trigger {
    margin-top: 1px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 40px;

    @include mq($until: tablet) {
      margin-top: 0;
    }

    @include mq($until: desktop) {
      font-size: 18px;
      line-height: 20px;
      margin-top: 6px;
      min-height: unset;
    }
    &::after {
      content: url(../assets/img/chevron-down.svg);
      position: absolute;
      right: 0;
      top: 16px;
      transform-origin: center;
      display: block;
      transition: transform 200ms;
      line-height: 0;
      font-size: 0;

      @include mq($until: desktop) {
        top: 6px;
      }
    }
    &.is-open::after {
      transform: rotate(180deg);
    }
  }
}

.Toastify__toast-container {
  width: 50% !important;
  padding: 0 32px !important;
  left: toRem(0) !important;

  @include mq($until: desktop) {
    width: 100% !important;
  }
}

.Toastify__toast {
  font-family: 'ARS Maquette Pro' !important;
  // position: absolute !important;
  // bottom: 0 !important;
  padding: 16px !important;
  width: calc(100% - 64px) !important;
  border: 1px solid var(--neutral200) !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08) !important;
}

.mic-toast {
  top: 114px !important;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }

  & > div {
    width: 100% !important;
  }
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__close-button > svg {
  fill: #768190 !important;
}

.Toastify__close-button {
  opacity: 1 !important;
  margin-top: 8px !important;
  margin-right: 4px !important;
}

// -------------------------------

.PhoneInput {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid var(--neutral300);
  position: relative;
  padding: 1px;
  padding-left: 0;
  height: 48px;
  margin-top: 8px;
  transition: all 0.15s ease-in-out;
  &--focus {
    box-shadow: 0 0 0 2px var(--blue);
    border: 0;
  }

  &.error {
    box-shadow: 0 0 0 2px var(--red);
    border: 0;
  }

  &:hover {
    border: 1px solid var(--neutral300);
  }
  .PhoneInputCountry {
    padding: 0.75rem 1rem;
  }
  input {
    @extend %family-maquette-regular;
    display: block;
    color: var(--neutral600);
    width: 100%;
    padding: toRem(12) toRem(16);
    height: 100%;
    line-height: toRem(24);
    font-size: toRem(16);
    outline: 0;
    border: 0;

    &::placeholder {
      color: var(--neutral300);
    }
    &:focus + .wrapperHelper .onFocusBorder {
      opacity: 1;
    }
    &:disabled {
      background-color: var(--neutral100) !important;
    }
  }

  .PhoneInputInput {
    padding-left: 0;
  }
}
