@import '@styles/basic';

.container {
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 24px;

  .control {
    display: block;
    position: relative;
    padding-left: 57px;

    cursor: pointer;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .control span {
    opacity: 0.4;
  }
  .controlIndicator {
    position: absolute;
    top: 4px;
    left: 0;
    height: 24px;
    width: 42px;
    border-radius: 24px;
    background: var(--neutral200);
    transition: all 0.3s ease-in-out;
  }

  .control input ~ .controlIndicator:after {
    display: block;
    left: 3px;
    top: 4px;
    border: none;
    border-radius: 50%;
    padding: 8.2px;
    background-color: var(--white);
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
  }

  // .control:hover input ~ .controlIndicator,
  // .control input:focus ~ .controlIndicator {
  //   background: white;
  // }

  .control input:checked ~ .controlIndicator {
    background-color: var(--blue);
    border-color: var(--blue) !important;
  }

  .control:hover input:not([disabled]):checked ~ .controlIndicator,
  .control input:checked:focus ~ .controlIndicator {
    background: var(--blue);
    border-color: var(--blue) !important;
  }

  // .control input:disabled ~ .controlIndicator {
  //   background: #d3d3d3;
  //   opacity: 4;
  //   pointer-events: none;
  // }
  .controlIndicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    transition: all 0.3s ease-in-out;
    // display: none;
  }
  .control input:checked ~ .controlIndicator:after {
    display: block;
    left: 22px;
    top: 4px;
    border: none;
    border-radius: 50%;
    padding: 8.2px;
    background-color: var(--white);
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
  }
  .controlCheckbox .controlIndicator:after {
    // left: 8px;
    // top: 2px;
    // width: 4px;
    // height: 12px;
    // border: solid white;
    // border-width: 0 3px 3px 0;
    // transform: rotate(45deg);
  }
  // .controlCheckbox input:disabled ~ .controlIndicator:after {
  //   border-color: #c0c0c0;
  // }
}
