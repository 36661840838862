@import '@styles/basic';
.container {
  button {
    margin-bottom: 16px;
  }
  h3 {
    color: var(--neutral700);
    letter-spacing: 0;
    margin-bottom: toRem(40);
    @extend %family-maquette-regular;

    @include mq($until: desktop) {
      margin-bottom: toRem(24);
    }

    span {
      position: relative;
    }
  }

  .subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: toRem(40);
  }
  .vector {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  p {
    color: var(--neutral700);
    @extend %family-maquette-regular;
    font-size: toRem(14);
    letter-spacing: 0;
    line-height: toRem(24);
    margin-bottom: toRem(40);
  }
}
