@import '@styles/basic';

.container {
  // width: 100%;
  // max-width: 480px;
  .resetWrapper {
    margin-bottom: toRem(32);

    @include mq($until: tablet) {
      margin-bottom: toRem(24);
    }
  }

  .goToSignUpWrap {
    margin-top: toRem(24);

    .signUpToDonate {
      @include mq($until: desktop) {
        display: none;
      }
    }

    .signUp {
      @include mq($from: desktop) {
        display: none;
      }
    }
  }
}
