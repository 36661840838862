@import '@styles/basic';
.container {
  .wrapper {
    .inputWrapper {
      position: relative;
      order: -1;

      input {
        @extend %family-maquette-regular;
        margin-right: 16px;
        align-self: flex-start;
        display: none;
        cursor: pointer;

        &:checked + div::before {
          background-color: var(--blue);
          border: none;
          padding: 8.2px;
          background-image: url(../../assets/img/checkbox-tick.svg);
          background-position: 50%;
          background-repeat: no-repeat;
          content: '';
        }

        &:disabled + div::before {
          border-color: var(--neutral400) !important;
          border: 1px solid;
          opacity: 0.4;
        }

        &:disabled + div {
          opacity: 0.4;

          &::before {
            cursor: default;
          }
        }

        &.intermediate {
          &:checked + div::before {
            background-image: url(../../assets/img/checkbox-intermediate.svg);
          }
        }
      }

      .checkboxText {
        display: flex;
        // align-items: center;

        @include mq($until: desktop) {
          font-size: 14px;
          line-height: 24px;
        }
        &::before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid var(--neutral400);
          width: 16px;
          height: 16px;
          border-radius: 2.6px;
          padding: 7px;
          display: inline-block;
          margin-top: 6px;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }

    label {
      @extend %family-maquette-regular;
      margin-bottom: toRem(4);
      margin: 0;
      order: 3;

      @include mq($until: tablet) {
        font-size: toRem(16);
        line-height: 24px;
        margin-bottom: toRem(4);
      }
    }

    &.error {
      .onFocusBorder {
        box-shadow: 0 0 0 2px var(--red);
        border-radius: 2px;
        display: block;
      }
    }
  }
  .error + p {
    color: var(--red);
    font-size: toRem(12);
    line-height: 1;
    margin-top: toRem(8);
  }
}
