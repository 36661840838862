@import '@styles/basic';

.container {
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 24px;
  @include mq($until: tablet) {
    margin-bottom: 32px;
  }
  form {
    button {
      margin-top: 0 !important;
      @include mq($until: desktop) {
        margin-top: 16px !important;
      }
    }

    .isHalf {
      display: flex;
      justify-content: space-between;
      > * {
        max-width: calc(50% - 8px);
        width: 100%;
      }

      @include mq($until: desktop) {
        flex-direction: column;
        > * {
          max-width: 100% !important;
          width: 100%;
        }
      }
    }

    .buttonUpdate {
      display: flex;
      align-items: center;

      button {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      .error {
        color: var(--red);
        font-size: toRem(16);
        line-height: 1;

        margin-left: 8px;
        @include mq($until: tablet) {
          font-size: toRem(14);
        }
      }

      .success {
        color: var(--green);
        font-size: toRem(16);
        line-height: 1;

        margin-left: 8px;
        @include mq($until: tablet) {
          font-size: toRem(14);
        }
      }
    }
  }
}
