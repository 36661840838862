%family-maquette-bold {
  font-family: 'ARS Maquette Pro';
  font-weight: 600;
}

%family-maquette-medium {
  font-family: 'ARS Maquette Pro';
  font-weight: 500;
}

%family-maquette-regular {
  font-family: 'ARS Maquette Pro';
  font-weight: 400;
}

%no-printable {
  @media print {
    display: none !important;
  }
}
