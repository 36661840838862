@import '@styles/basic';
.wrapper {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    padding: 16px 24px;
    box-shadow: 0px 1px 0 0 var(--neutral200);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      position: absolute;
      right: 24px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .body {
    margin-bottom: 64px;
    max-height: calc(80vh);
    overflow: hidden;
    padding: 0 24px;
    overflow-y: scroll;

    .scroll {
      padding-top: 16px;
      padding-bottom: 112px;
    }
  }
}

.footer {
  display: flex;
  background-color: white;
  margin-top: auto;
  box-shadow: 0px -1px 0 0 var(--dark-grey);
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  button {
    width: auto;

    &:first-of-type {
      margin-right: 4px;
    }

    &:nth-of-type(2) {
      margin-left: 4px;
    }
  }
}
