@import '@styles/basic';
.container {
  margin-bottom: toRem(16);
  &:not(:last-child) {
  }

  .wrapper {
    .inputWrapper {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      border: 1px solid var(--neutral300);
      position: relative;
      padding: 1px;
      height: 48px;

      input {
        @extend %family-maquette-regular;
        display: block;
        color: var(--neutral600);
        width: 100%;
        padding: toRem(12) toRem(16);
        height: 100%;
        line-height: toRem(24);
        font-size: toRem(16);
        outline: 0;
        border: 0;

        &::placeholder {
          color: var(--neutral300);
        }
        &:focus + .wrapperHelper .onFocusBorder {
          opacity: 1;
        }
        &:disabled {
          background-color: var(--neutral100) !important;

          &:hover {
            border: 1px solid var(--neutral300);
          }
        }
      }

      &:hover {
        border: 1px solid var(--neutral400);
      }
    }

    label {
      @extend %family-maquette-regular;
      margin-bottom: toRem(4);
      line-height: 24px;
      display: block;
      font-size: toRem(16);
    }

    &.error {
      .onFocusBorder {
        box-shadow: 0 0 0 2px var(--red);
        border-radius: 2px;
        display: block;
      }
      input {
        color: var(--neutral600);
        &::placeholder {
          color: var(--neutral300);
          font-weight: 400;
          font-size: toRem(16);
          line-height: toRem(24);
          color: var(--neutral300);
        }
      }
    }
  }
  .error + p {
    color: var(--red);
    font-size: toRem(12);
    line-height: 1;
    margin-top: toRem(8);
  }

  .onFocusBorder {
    position: absolute;
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
    top: 0;
    pointer-events: none;
    left: 0;
    box-shadow: 0 0 0 2px var(--blue);
    border-radius: 2px;
    width: 100%;
    height: 100%;
  }
}
