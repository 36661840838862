@import '@styles/basic';

.inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  gap: toRem(24);
  position: relative;

  .closeBtn {
    position: absolute;
    right: 22px;
    top: 22px;
    &:hover {
      cursor: pointer;
    }
  }

  .container {
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      flex-shrink: 0;
      line-height: 32px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--yellow-light);
      margin-bottom: toRem(24);
    }

    h3 {
      margin-bottom: toRem(8);
    }

    p {
      color: var(--neutral400);
      max-width: toRem(392);
    }
  }

  .footerModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: toRem(16);
    width: 100%;
  }
}
