@import '@styles/basic';

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.hideSubtitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--neutral400);
}
.subtitles {
  margin-bottom: 40px;
  margin-top: -32px;

  a {
    text-decoration: none;
  }
}
