@import '@styles/basic';

.header {
  position: relative;
  position: sticky;
  top: 0;
  display: flex;
  background-color: var(--white);
  box-shadow: inset 0px -1px 0px var(--neutral200);
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding: 12px 16px;

  @media print {
    display: none !important;
  }

  @include mq($from: tablet) {
    padding: 16px 24px;
  }

  .logo {
    display: block;
    margin-right: 16px;
    height: 40px;
    flex-shrink: 0;
  }

  .navigation {
    display: flex;
    align-items: center;
    button {
      margin: 0 !important;
    }
  }
}

.progressBar {
  height: 8px;
  width: 100%;
  position: relative;

  .insideProgressBar {
    position: absolute;
    top: 0;
    background: var(--blue);
    bottom: 0;
    height: 100%;
  }
}
