@import '@styles/basic';
.container {
  padding: 0 toRem(12);
  button {
    margin-bottom: 16px;
  }
  h3 {
    margin-bottom: toRem(16);
  }
  p {
    margin-bottom: toRem(40);
  }
}
