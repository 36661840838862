@import '@styles/basic';

.inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($until: tablet) {
    position: relative;
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--neutral200);
    background-color: var(--white);
    padding: 16px;
    z-index: 5;

    position: relative;
    p {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }

    .cancelIcon {
      flex-shrink: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .container {
    padding: 24px;
    line-height: 24px;
    text-align: center;
    width: calc(100% - 20px);
    // position: relative;
    // height: calc(100% - 81px);
    @include mq($until: tablet) {
      position: absolute;
      top: 74px;
    }

    .faqContainer {
      text-align: left;
      // width: calc(100% - 10px);
      .listItem {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        position: relative;
        cursor: pointer;
        @include mq($until: tablet) {
          font-size: 14px;
          line-height: 20px;
        }
        &:not(:last-child) {
          margin-bottom: 18px;
        }
        &::after {
          content: url(../../assets/img/chevron-down.svg);
          position: absolute;
          right: 0;
          top: 12px !important;
          transform-origin: center;
          display: block;
          transition: transform 200ms;
          line-height: 0;
          font-size: 0;
          transform: rotate(-90deg);
          right: -12px;
          @include mq($until: desktop) {
            top: 6px;
          }
        }
      }
    }
  }

  .error {
    color: var(--red);
    font-size: toRem(16);
    line-height: 1;
    margin-top: toRem(8);
    text-align: left;
    @media screen and (max-width: 416px) {
      margin-bottom: 18px;
      font-size: toRem(14);
    }
  }

  .success {
    color: var(--green);
    font-size: toRem(16);
    line-height: 1;
    text-align: center;
    margin-top: toRem(8);
    @media screen and (max-width: 416px) {
      margin-bottom: 18px;
      font-size: toRem(14);
    }
  }
}
