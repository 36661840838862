@import '@styles/basic';

.errorMessage {
  color: var(--red);
  font-size: 12px;
  margin-top: 8px;
  font-weight: 700;
  max-width: 480px;
  align-self: center;
  width: 100%;
}
