@import '@styles/basic';
@import 'sass-mq';
.wrapper {
  > p {
    color: var(--neutral600);
    font-size: toRem(32);
  }

  .container {
    .spinnerContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .hide {
      opacity: 0;
    }
    .errorMessage {
      font-size: toRem(12);
      line-height: 1;
      margin-top: toRem(12);
      align-self: flex-start;
    }

    .errorMessage {
      color: var(--red);
    }
  }
}
