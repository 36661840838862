// @import '@styles/basic';
// .container {
//   height: 100%;
//   max-width: 480px;
//   padding-bottom: 24px;
//   @include mq($from: desktop) {
//     padding-top: 32px;
//   }

//   .spinnerContainer {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     span {
//       margin-left: 8px;
//       font-weight: 500;
//       font-size: 16px;
//       line-height: 24px;
//     }
//   }

//   .hide {
//     display: none;
//   }

//   .advice {
//     margin-top: 16px;

//     & + button {
//       margin-top: 40px !important;
//     }
//   }
//   .errorMessage {
//     font-size: toRem(12);
//     line-height: 1;
//     margin-top: toRem(12);
//     align-self: flex-start;
//     color: var(--red);
//   }
// }

@import '@styles/basic';
.container {
  .list {
    .item {
      display: flex;
      align-items: center;
      gap: toRem(16);
      &:first-child {
        margin-bottom: toRem(16);
      }
      &:last-child {
        margin-bottom: toRem(40);
      }
      p {
        margin-bottom: 0;

        &.title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
      }
      .icon {
        flex-shrink: 0;
        line-height: 32px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--neutral100);

        @include mq($until: desktop) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
