@import '@styles/basic';
.container {
  height: 100%;
  max-width: 480px;
  // padding-bottom: 24px;
  position: relative;
  @media print {
    display: none !important;
  }

  @include mq($from: desktop) {
    padding-top: 32px;
  }

  .recorderContainer {
  }
  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .hide {
    display: none;
  }

  .cancelUpload {
    margin-top: 8px;
  }
}
