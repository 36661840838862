@import '@styles/basic';

.controlsContainer {
  // height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0.5rem;
  flex-direction: column;
  min-height: 223px;

  .recordingsContainer {
    padding: 16px 18px;
    width: 100%;
    position: absolute;
    top: 22px;

    @include mq($until: desktop) {
      top: -10px;
    }

    audio {
      width: 100%;
    }

    &.isFirefox {
      padding: 24px 16px;

      audio {
        border-radius: 20px;
      }
    }

    &.isSafari {
      padding: 28px 18px;
    }
  }

  .recorderDisplay {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 2rem;

    .recordingTime {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .messageState {
    p {
      margin: 24px 0;
    }
  }

  .startButtonContainer {
    display: flex;

    button {
      align-self: center !important;
    }

    .spinnerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 24px;
        margin-bottom: 40px;
      }

      .error {
        color: var(--red);
      }
    }

    .downloadLink {
      display: flex;
      align-items: center
    }
  }
}

@keyframes animated-block {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
