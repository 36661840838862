@import '@styles/basic';

.container {
  display: flex;
  gap: toRem(16);
}
.icon {
  flex-shrink: 0;
  line-height: 32px;
  font-size: 18px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  font-family: 'ARS Maquette Pro' !important;
  .title {
    font-size: toRem(18) !important;
    font-weight: 500 !important;
    line-height: toRem(24) !important;
    color: #000 !important;
    justify-content: center;
    align-items: center;
  }
  .body {
    .message {
      font-size: toRem(14);
      font-weight: 400;
      line-height: toRem(24);
    }
  }
}
