@import '@styles/basic';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: var(--white);
  border: 1px solid var(--neutral200);
  position: relative;
  display: flex;
  align-items: center;
  padding: toRem(12) toRem(16);
  border-radius: toRem(24);
  box-sizing: border-box;
  height: 48px;
  transition: background ease-in-out 0.2s;
  p {
    font-size: 16px;
    line-height: 24px;
    @extend %family-maquette-regular;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  span {
    margin-left: 4px;
  }

  .birthday {
    min-width: 18px;
    min-height: 18px;
    flex-shrink: 0;
    display: flex;
    align-self: center;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.isOnFocus {
    border: 1px solid var(--neutral300);
    background-color: var(--neutral100);
    pointer-events: none;
  }

  &.isActive {
    border: 2px solid var(--neutral600);
  }

  .leadingIcon {
    margin-right: 8px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 580px) {
  .container {
    padding: 6px 16px;
  }
}
