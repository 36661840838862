@import '@styles/basic';
form {
  .spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hide {
    opacity: 0;
  }
}
