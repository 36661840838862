@import '@styles/basic';

.container {
  // width: 100%;
  // max-width: 480px;
  // @include mq($until: desktop) {
  //   max-width: 480px !important;
  // }
  form {
    .spinnerContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .hide {
      opacity: 0;
    }

    .isHalf {
      display: flex;

      justify-content: space-between;
      > * {
        max-width: calc(50% - 8px);
        width: 100%;
      }

      @include mq($until: desktop) {
        flex-direction: column;
        > * {
          max-width: 100% !important;
          width: 100%;
        }
      }
    }

    .scrollableWrap {
      @media screen and (max-width: 344px) {
        height: calc(100vh - 225px) !important; // 186??
        // height: calc(100vh - 210px) !important;
      }

      @include mq($until: tablet) {
        // height: calc(100vh - 187px);
        // height: calc(100vh - 192px);
        height: calc(100vh - 225px); // before when everything was ok
        // height: calc(100vh - 191px);
        padding: 0;

        overflow-y: auto;
        max-height: 100%;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .agreementTitle {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        @include mq($until: tablet) {
          margin-top: 24px;
        }
      }

      .agreementContent {
        max-width: 480px;
        height: 480px;
        background: var(--white);
        border: 1px solid var(--neutral300);
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-bottom: 16px;
        white-space: pre-line;

        @include mq($until: tablet) {
          border: 0;
          box-shadow: none;
          border-radius: 0;
          height: 100%;
        }

        .innerContent {
          line-height: 24px;
          padding: 0 16px;
          overflow-y: auto;
          max-height: 100%;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }

          @include mq($until: tablet) {
            padding: 0;
            overflow-y: unset;
          }
          p {
            margin-top: 16px;
            @include mq($until: tablet) {
              margin-bottom: 16px;
            }
          }

          strong {
            font-weight: 700;
          }

          i {
            font-style: italic;
          }

          .titleSection {
            font-weight: 700;
            font-size: 20px;
          }

          .titleParagraph {
            font-weight: 700;
          }

          .clause34,
          .clause35 {
            margin-top: 16px;

            p {
              display: inline-block;
              margin: 0;
            }
          }
          ul:not(.dotted) {
            li {
              & > strong:first-child {
                margin-right: 4px;
              }
            }
          }

          ul.list {
            margin: 16px 0;
            &.dotted {
              li {
                list-style: disc;
                margin-left: 24px;
              }
            }
            &.dashed {
              list-style-type: none;
              & > li {
                text-indent: -5px;
                margin-left: 12px;
                &:before {
                  content: '-';
                  text-indent: -5px;
                  margin-right: 4px;
                }
              }
            }
            &.list6 {
              li > i {
                display: inline-block;
                margin-top: 12px;
              }
            }
            li {
              // list-style: decimal;
              line-height: 24px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .agreement {
      margin-top: 24px;
    }

    // .agreeFooter {
    //   position: relative;
    //   background-color: var(--white);
    //   margin-top: 16px;
    //   @include mq($until: tablet) {
    //     position: fixed;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //   }
    //   .agreeFooterWrap {
    //     button {
    //       @include mq($until: tablet) {
    //         margin: 16px 0 0 0 !important;
    //       }
    //     }
    //     @include mq($until: tablet) {
    //       border-top: 1px solid var(--neutral200);
    //       padding: 16px;
    //       // When a user is on a iPhone X device, we can use this user agent styling to
    //       // ensure that the right amount of space is given to prevent phone UI form
    //       // interfering with our element
    //       padding-bottom: calc(16px + env(safe-area-inset-bottom));
    //     }
    //   }
    // }

    .errorMessage {
      color: var(--red);
      font-size: 12px;
      margin-top: 8px;
      font-weight: 700;
    }

    &.agreeForm {
      @include mq($until: tablet) {
        margin-top: -30px;
      }
    }
  }
}
