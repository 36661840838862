@import '@styles/basic';
.container {
  width: 100%;
  background: var(--neutral100);
  border-radius: 32px;
  color: var(--neutral600);
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  .navigation {
    width: 100%;
    margin: 0 20px;
    .navigationWrapper {
      min-width: 100%;
      background-color: var(--neutral200);
      height: 4px;
      border-radius: 2px;
      cursor: pointer;

      .seekBar {
        width: 0;
        height: 100%;
        background-color: var(--blue);
        border-radius: 2px;
      }
    }
  }
  .controls {
    font-size: inherit;
    display: flex;
    align-items: center;

    .btnAction {
      font-size: 2rem;

      cursor: pointer;
    }

    .pp {
      font-size: 4rem;
    }
  }
}
