@import '@styles/basic';

.container {
  margin-bottom: 40px;
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  @include mq($until: tablet) {
    margin-bottom: 32px;
  }
  .step {
    display: flex;
    align-items: center;
    padding: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--neutral200);
    }
    .icon {
      margin-right: 16px;
      flex-shrink: 0;
      line-height: 32px;
      font-size: 18px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq($until: desktop) {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      &.icon1 {
        background-color: var(--green);
      }

      &.icon2 {
        background-color: var(--orange);
        align-self: flex-start;

        & + .content {
          p:first-child {
            margin: 4px 0;
          }
        }
      }
      &.icon2Selected {
        background-color: var(--green);
      }
      &.icon3 {
        align-self: flex-start;
        background-color: var(--orange-light);
        & + .content {
          p:first-child {
            margin: 4px 0;
          }
        }
      }
      &.icon3Selected {
        background-color: var(--orange);
      }

      &.icon4 {
        background-color: var(--orange-light);
      }
      &.icon4Selected {
        background-color: var(--orange);
      }
    }

    .content {
      color: var(--neutral600);
      flex: 1;
      p:nth-of-type(2) {
        color: var(--neutral400);
      }

      &.done p {
        font-weight: 400;
      }

      &.toDo p:first-child {
        font-weight: 500;
      }
      &.opacity p {
        font-weight: 400;
        opacity: 0.4;
      }

      .counter {
        font-size: 14px;
        margin-top: 10px;
        strong {
          font-weight: 700;
        }
      }
    }

    .progressCta {
      margin-top: toRem(16);
      display: flex;
      gap: toRem(20);
      align-items: center;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .progress {
        display: flex;
        gap: toRem(20);
        align-items: center;
        flex: 1;
        width: 100%;
        flex-shrink: 0;
        span {
          flex-shrink: 0;
        }
        .progressBar {
          height: 4px;
          width: 100%;
          border-radius: 2px !important;
          position: relative;
          background: var(--neutral200);

          .insideProgressBar {
            position: absolute;
            top: 0;
            background: var(--blue);
            bottom: 0;
            height: 100%;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
