@import '@styles/basic';

.splitLayout {
  display: flex;
  // min-height: calc(100vh - 96px);

  &.internalRecWrap {
    @include mq($until: tablet) {
      height: calc(100vh - 72px) !important;
    }
    @include mq($until: desktop) {
      // flex-direction: column;
      height: calc(100vh - 80px);
      position: relative;
    }

    @media print {
      height: 100%;
    }

    & > section:first-child {
      @include mq($until: desktop) {
        // padding: 16px !important;
        position: fixed;
        background-color: white;
        bottom: 0;
        left: 0;
        padding: 0;
        z-index: 9999;
      }

      & > div {
        @include mq($until: desktop) {
          border-top: 1px solid var(--neutral200);
          padding: 16px;
          // When a user is on a iPhone X device, we can use this user agent styling to
          // ensure that the right amount of space is given to prevent phone UI form
          // interfering with our element
          padding-bottom: calc(16px + env(safe-area-inset-bottom));
          max-width: 100%;
        }

        & > div {
          @include mq($until: desktop) {
            max-width: 100%;
          }
        }
      }
    }
  }
  @include mq($from: tablet) {
    min-height: calc(100vh - 80px);
  }
  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;

    // height: calc(100vh - 80px);
    // align-items: center;

    // @include mq($until: desktop) {
    //   height: calc(100vh - 136px);
    // }

    @include mq($from: desktop) {
      align-items: center;
      width: 50%;
    }

    // @include mq($until: desktop) {
    //   align-items: flex-start;
    //   margin: 24px 0 0 0;
    //   height: calc(100vh - 112px);
    // }
    // @include mq($until: mobile) {
    //   margin: 24px 0;
    // }
    &:not(:nth-child(2)) {
      padding: 0 16px;

      @include mq($until: desktop) {
        padding: 24px 16px;
      }
    }

    &:nth-child(2) {
      position: fixed;
      right: 0;
      @include mq($until: desktop) {
        display: none;
      }
      .content {
        max-width: 100%;

        // margin: 0;
        // padding: 0;
      }
    }

    &.internalRecWrap {
      @include mq($until: desktop) {
        position: relative;
        display: block;
        width: 100%;
        order: -1;
        // height: 70%;
        overflow: hidden;
        border-bottom: 1px solid var(--neutral200);
        height: 100%;
      }

      @media print {
        height: 100%;
        overflow: visible;
        border: 0;
      }
      .content {
        max-width: 100%;

        .container {
        }
      }
    }

    .content {
      // @include mq($from: desktop) {
      //   margin: auto;
      // }
      // display: flex;
      // justify-content: center;
      // flex-direction: column;
      // align-items: center;
      margin: 0 auto;
      width: 100%;
      max-width: 480px;
    }
  }
}
