@import '@styles/basic';
.footerCookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  align-items: center;
  padding: 14px 16px;
  background-color: #768190;
  display: flex;
  z-index: 5;
  color: white;
  justify-content: space-between;

  @include mq($until: desktop) {
    flex-direction: column;
  }

  .footerCookieText {
    width: 100%;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    text-align: center;

    a {
      color: var(--white);
    }
  }
  .footerButton {
    margin: 0;
    padding: 6px 10px;
    border: none;
    font: inherit;
    color: inherit;
    background: none;
    background-color: #00479a;
    border-radius: 2px;
    color: var(--white);
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    box-shadow: 0px 0px 0px 1px #00479a;
    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    color: var(--white);
    @include mq($until: desktop) {
      margin-top: 12px;
    }
    &:hover {
      cursor: pointer;
      background-color: #00479a;
      box-shadow: 0px 0px 0px 1px #00479a;
    }
  }
}
