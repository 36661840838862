@import '@styles/basic';
.control {
  display: block;
  position: relative;
  padding-left: 24px;

  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control span {
  opacity: 0.4;
}
.controlIndicator {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background: white;
  border: 1px solid var(--neutral300);
  border-radius: 50%;
}
.control:hover input ~ .controlIndicator,
.control input:focus ~ .controlIndicator {
  background: white;
}

.control input:checked ~ .controlIndicator {
  background-color: var(--blue);
  border-color: var(--blue) !important;
}
.control:hover input:not([disabled]):checked ~ .controlIndicator,
.control input:checked:focus ~ .controlIndicator {
  background: var(--blue);
  border-color: var(--blue) !important;
}
.control input:disabled ~ .controlIndicator {
  background: #d3d3d3;
  opacity: 4;
  pointer-events: none;
}
.controlIndicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .controlIndicator:after {
  display: block;
  right: -1px;
  top: -1px;
  border: none;
  padding: 8.2px;
  background-image: url(../../assets/img/checkbox-tick.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  content: '';
}
.controlCheckbox .controlIndicator:after {
  // left: 8px;
  // top: 2px;
  // width: 4px;
  // height: 12px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // transform: rotate(45deg);
}
.controlCheckbox input:disabled ~ .controlIndicator:after {
  border-color: #c0c0c0;
}
