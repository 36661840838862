@import '@styles/basic';
.container {
  display: flex;
  justify-content: center;

  .wrapper {
    margin: 56px;

    @include mq($until: tablet) {
      margin-top: 24px;
      padding: 0 16px;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
    width: 100%;
    max-width: 1328px;
  }
}
