@import '@styles/basic';

.layout {
  display: flex;
  // min-height: calc(100vh - 72px);
  @include mq($from: tablet) {
    min-height: calc(100vh - 80px);
  }
  .wrapper {
    display: block;
    width: 100%;
    @include mq($from: tablet) {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
    .content {
      width: 100%;
      height: 100%;
    }
  }
}
