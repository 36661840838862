.imageWrap {
  width: 100%;
  background-position: center;
  height: calc(100vh - 80px);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.textWrap {
  position: absolute;
  bottom: 80px;
  color: var(--white);
  margin: 0 80px;

  h4 {
    margin-bottom: 16px;
  }
}
