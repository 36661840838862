@import '@styles/basic';

.faqContainer {
  .inner {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq($until: tablet) {
      position: relative;
    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      justify-content: space-between;
      border-bottom: 1px solid var(--neutral200);
      background-color: var(--white);

      z-index: 5;
      padding: 16px;

      @include mq($until: tablet) {
        position: fixed;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }

      .backWrapper {
        display: flex;
        align-items: center;

        h4 {
          margin-left: 8px;
          &.truncate {
            @include truncate(800px);
            max-width: 320px;

            @media screen and (max-width: 446px) {
              max-width: 280px;
            }
            @media screen and (max-width: 410px) {
              max-width: 250px;
            }
            @media screen and (max-width: 372px) {
              max-width: 240px;
            }
            @media screen and (max-width: 362px) {
              max-width: 200px;
            }
          }
        }
      }

      .cancelIcon {
        flex-shrink: 0;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .container {
      padding: 24px 72px;
      line-height: 24px;
      overflow-y: scroll;
      text-align: center;
      max-height: 520px;
      white-space: pre-line;
      text-align: left;
      max-width: 960px;
      // position: relative;
      // height: calc(100% - 81px);
      @include mq($until: tablet) {
        position: absolute;
        overflow-y: unset;
        top: 74px;
        padding: 24px;
      }

      @media screen and (max-width: 520px) {
        top: 98px;
      }

      @media screen and (max-width: 500px) {
        top: 74px;
      }

      // @include mq($until: mobile) {
      //   position: absolute;
      //   top: 89px;
      // }
    }

    .error {
      color: var(--red);
      font-size: toRem(16);
      line-height: 1;
      margin-top: toRem(8);
      text-align: left;
      @media screen and (max-width: 416px) {
        margin-bottom: 18px;
        font-size: toRem(14);
      }
    }

    .success {
      color: var(--green);
      font-size: toRem(16);
      line-height: 1;
      text-align: left;
      margin-top: toRem(8);
      @media screen and (max-width: 416px) {
        margin-bottom: 18px;
        font-size: toRem(14);
      }
    }
  }
}
