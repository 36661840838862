@import '@styles/basic';

.item {
  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.dropdownContent {
  padding: 24px 24px 16px 24px;
  max-height: 300px;
  overflow-y: scroll;
}

.dropdownFooter {
  padding: 0 24px 24px 24px;
  display: flex;
  align-items: center;

  border-top: 1px solid var(--dark-grey);

  button:first-of-type {
    margin-right: 8px;
  }
}

.checkboxWrapper {
  margin-bottom: 16px;
  display: block;
}

.rangeContainer {
  display: flex;
  justify-content: space-between;

  &::after {
    @include mq($until: tablet) {
      content: '-';
      z-index: 99;
      position: absolute;
      right: 48%;
      transform: translate(-50%, -50%);
      top: 118px;
    }
  }

  & > label:first-child::before {
    @include mq($from: tablet) {
      content: '-';
      z-index: 99;
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translate(-50%, 0%);
    }
  }
}
