@import '@styles/basic';
@import 'sass-mq';
.wrapper {
  min-width: 480px;
  flex-direction: column;
  @include mq($until: tablet) {
    min-width: 100%;
  }
  > p {
    color: var(--primary);
    font-size: toRem(32);
  }

  button:last-of-type {
    margin-top: 12px;
  }

  .container {
    position: relative;
  }

  .errorMessage {
    font-size: toRem(12);
    line-height: 1;
    margin-top: toRem(12);
    align-self: flex-start;
  }

  .errorMessage {
    color: var(--red);
  }
}
