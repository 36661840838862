@import '@styles/basic';
.container {
  background-color: var(--neutral100);
  height: calc(100vh - 80px);
  padding: 0 80px;
  white-space: pre-line;
  @media print {
    background-color: var(--white);
  }

  @include mq($until: tablet) {
    height: calc(100vh - 330px) !important;
  }

  @include mq($until: desktop) {
    padding: 0 16px;
    // height: 100%;
    height: calc(100vh - 344px);
    white-space: pre-line;
  }

  .innerContainer {
    overflow-y: auto;
    max-height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    height: 100%;
    @include mq($until: tablet) {
      height: calc(100vh - 295px) !important;
      padding: 0;
    }

    @include mq($until: desktop) {
      height: calc(100vh - 303px);
    }
    @include mq($from: desktop) {
      padding: 80px 0;
    }

    @media print {
      overflow-y: visible;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      @include mq($until: desktop) {
        margin: 16px 0;
      }
      h4 {
        color: var(--neutral400);

        @include mq($until: desktop) {
          font-size: 18px;
          line-height: 24px;
        }

        &.desktop {
          @include mq($until: desktop) {
            display: none;
          }
        }
        &.mobile {
          display: none;
          @include mq($until: desktop) {
            display: inline-block;
          }
        }
      }

      svg {
        flex-shrink: 0;
        @media print {
          display: none;
        }
      }
    }

    .script {
      // height: calc(100vh - 254px);
      // white-space: pre-line;

      // @include mq($until: desktop) {
      //   // height: calc(100vh - 446px);
      //   // height: calc(100vh - 399px);
      //   height: calc(100vh - 359px);
      // }
      @media print {
        width: 100%;
        height: 100%;
      }
      .innerContent {
        // overflow-y: auto;
        // max-height: 100%;
        // -ms-overflow-style: none; /* IE and Edge */
        // scrollbar-width: none; /* Firefox */

        // @media print {
        //   overflow-y: visible;
        // }

        // &::-webkit-scrollbar {
        //   display: none;
        // }
        p {
          font-size: 24px;
          font-weight: 400;
          line-height: 40px;

          @include mq($until: desktop) {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
