@import '@styles/basic';
.container {
  display: flex;
  flex-wrap: nowrap;

  & > ul > div {
    @include mq($until: tablet) {
      display: flex;
    }
  }

  .swiper {
    /* overflow: hidden !important; */
    width: 100%;
  }
}

.slide {
  width: auto;
  display: flex;
  align-self: center;
  button {
    span {
      white-space: nowrap;
    }
    & > div {
      flex-shrink: 0;
    }
  }
}

.removeFilterButton {
  display: flex;
  align-self: center;
  font-size: 16px;
  font-weight: 500;
  padding: 0 16px;
  cursor: pointer;
  white-space: nowrap;
}

.showSuggested {
  flex-shrink: 0;
  margin-left: 4px;
  button > span {
    white-space: nowrap;
  }
}
