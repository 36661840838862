@import '@styles/basic';

.inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($until: tablet) {
    position: relative;
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--neutral200);
    background-color: var(--white);
    padding: 24px;
    z-index: 5;
    padding-bottom: 24px;
    position: relative;
    p {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }

    .cancelIcon {
      flex-shrink: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .container {
    padding: 24px;
    line-height: 24px;
    text-align: center;
    // position: relative;
    // height: calc(100% - 81px);
    @include mq($until: tablet) {
      position: absolute;
      top: 74px;
    }
    @media screen and (max-width: 420px) {
      top: 104px;
    }

    @include mq($until: mobile) {
      position: absolute;
      top: 113px;
    }
    form {
      label {
        text-align: left;
        margin-bottom: 16px;
      }
    }
  }

  .footerModal {
    padding: 16px;

    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    border-top: 1px solid var(--neutral200);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 416px) {
      flex-direction: column;
      width: 100%;
    }

    .buttonsWrapper {
      display: flex !important;
      align-items: center;

      @media screen and (max-width: 416px) {
        width: 100%;
        order: 2;
      }
      p {
        margin-top: 0;
      }
    }
    button {
      margin-top: 0 !important;
    }
    .cancel {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: toRem(12) toRem(16);
      border-radius: toRem(24);
      box-sizing: border-box;
      transition: all ease-in-out 0.2s;
      font-weight: 500;
      margin-left: 8px;
      background-color: transparent;
      border: none;
      color: var(--blue);
      &:hover {
        background-color: var(--blue-light);
        border: none;
        color: var(--blue);
      }
      @media screen and (max-width: 416px) {
        margin-top: 4px;
      }
    }
  }

  .error {
    color: var(--red);
    font-size: toRem(16);
    line-height: 1;
    margin-top: toRem(8);
    text-align: left;
    @media screen and (max-width: 416px) {
      margin-bottom: 18px;
      font-size: toRem(14);
    }
  }

  .success {
    color: var(--green);
    font-size: toRem(16);
    line-height: 1;
    text-align: left;
    margin-top: toRem(8);
    @media screen and (max-width: 416px) {
      margin-bottom: 18px;
      font-size: toRem(14);
    }
  }
}
